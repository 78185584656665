<template>
  <v-card elevation="0">
    <v-row class="ma-2">
      <attachments
        ref="attachments"
        :items="attachments"
        :preview-url="previewUrl"
        permission="api.delete_shipmentattachment"
        disable-upload
        @error="onError"
        @select="onAttachmentSelected"
        @delete="onDeleteAttachment"
        @download-all="onDownloadAll"
      >
        <template #buttons>
          <v-btn
            v-if="canGenerate()"
            color="primary"
            dark
            class="mb-10 mr-2"
            @click="onGenerate"
          >
            {{ $t('shipment.re-generate-summary-document') }}
            <v-icon right dark>
              mdi-cube-send
            </v-icon>
          </v-btn>
        </template>
      </attachments>
    </v-row>
    <cant-finalize
      v-model="cantFinalizeDialog"
      @close="cantFinalizeDialog = false"
    />
  </v-card>
</template>

<script>
import ShipmentService from '@/services/ShipmentService.js';

export default {
  components: {
    attachments: () =>
      import('@/views/dashboard/components/files/attachmentList'),
    cantFinalize: () => import('./cantFinalize.vue')
  },
  props: {
    shipment: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      attachments: [],
      previewUrl: '',
      cantFinalizeDialog: false
    };
  },
  computed: {
    isNew() {
      return !this.shipment?.id;
    }
  },
  watch: {
    shipment: {
      async handler() {
        console.log('shipmentAttachments.shipment changed()');
        await this.init();
      },
      deep: true
    }
  },
  mounted() {
    console.log('shipmentAttachments.mounted()');
    this.init();
  },
  activated() {
    console.log('shipmentAttachments.activated()');
    this.init();
  },
  methods: {
    async loadAttachments() {
      console.log('shipmentAttachments.loadAttachments()');
      if (this.isNew) {
        return;
      }
      console.log('Loading attachments');
      [this.attachments] = await ShipmentService.listAttachments(this.shipment);
      console.log('attachments:', this.attachments);
    },
    async init() {
      this.previewUrl = '';
      this.attachments = [];
      try {
        await this.loadAttachments();
      } catch (err) {
        this.showError(this, err);
      }
    },
    async onAttachmentSelected(attachment) {
      if (!attachment) {
        this.previewUrl = '';
        return;
      }
      let url = await ShipmentService.downloadAttachment(
        this.shipment,
        attachment.path
      );
      console.log('attachment url: ', url);
      this.previewUrl = url;
    },
    onError(err) {
      this.showError(this, err);
    },
    canGenerate() {
      // if (!this.item.parcel_count) return false;
      if (!this.hasPermission('api.add_shipment')) return false;
      if (!this.shipment.client) return false;
      if (this.shipment.status != 'to send') return false;

      return true;
    },
    async onGenerate() {
      console.log('shipmentAttachments.onGenerate');
      if (this.isNew) return;
      // this.item = await ShipmentService.getShipment(this.item.id)
      if (!this.shipment.parcel_count) {
        this.cantFinalizeDialog = true;
        return;
      }

      try {
        const ret = await ShipmentService.createShipmentSummary(
          this.shipment.id
        );
        console.log('shipment.summary.ret=', ret);
      } catch (err) {
        this.showError(this, err);
      }
      await this.loadAttachments();
    },
    async onDeleteAttachment(attachment) {
      console.log('delete attachment:', attachment);
      try {
        let rsp = await ShipmentService.deleteAttachment(
          this.shipment,
          attachment
        );
        console.log('delete attachment.rsp:', rsp);
        this.$refs.attachments.deleted(attachment);
        this.attachments = this.attachments.filter(
          at => at.id != attachment.id
        );
      } catch (err) {
        this.showError(this, err);
      }
    },
    async onDownloadAll() {
      console.log('shipmentAttachments.onDownloadAll');
      const keys = this.attachments.map(at => at.path);
      try {
        const rsp = await ShipmentService.downloadAttachments(
          this.shipment,
          keys
        );

        let link = document.createElement('a');
        link.href = rsp.url;
        link.setAttribute('download', rsp.filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        this.showError(this, err);
      }
    }
  }
};
</script>

<style></style>
